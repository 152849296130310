import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { withTranslation } from 'react-i18next'

import UserNav from './UserNav'
import ObjectPopup from '../objects/SingleObject'
import Table from '../../components/table'

import dates from '../../utils/dates'

import AssService from '../../services/AssService'
//import Popup from 'reactjs-popup'
//import Select from 'react-select'
import config from '../../config'
//import { Visibility } from '@tanstack/react-table'
import AddCircleIcon from '@mui/icons-material/AddCircle';
//import DeleteIcon from '@mui/icons-material/Delete';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Gateway from '../../components/Gateway/Gateway'
import DeletePopUp from '../../components/popups/DeletePopUp'
import AddGatewayPopUp from '../../components/popups/AddGatewayPopUp'
import AddObjectPopUp from '../../components/popups/AddObjectPopUp'

function UserPage ({ i18n, t }) {
  const { userId } = useParams()

  const [editPopupContent, setEditPopupContent] = useState({})
  const [editPopupState, setEditPopupState] = useState(false)
  
  //const [addPopupState, setAddPopupState] = useState(false)
  const [addGatewayPopupState, setAddGatewayPopupState] = useState(false)
  const [addObjectPopupState, setAddObjectPopupState] = useState(false)
  const [dummyState, setDummyState] = useState(0);

  const [confirmPopupState, setConfirmPopupState] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)

  const [otaStatus, setOtaStatus] = useState(false)
  const [otaUrl, setOtaUrl] = useState('')
  const [connectedObject,setConnectedObject] = useState(0)
  //const [readOnly, setReadOnly] = useState(true)
  //const [type, setType] = useState({})
  //const [id, setId] = useState('')
  //const [mac, setMac] = useState('')
  //const [VisibilityMac, setVisibilityMac] = useState(false)

  const [gatewaysList, setGatewaysList] = useState([])

  const queryClient = new QueryClient()

  useEffect(()=>{
    if(otaStatus)
    {
      console.log("OTA request")
      var criteria = {ids:[editPopupContent.id]}
      AssService.sendOtaUpgradeRequest(otaUrl,criteria)
      setEditPopupState(false)
      setOtaStatus(false)
    }
  },[otaStatus])

  useEffect(()=>{
    let query = {}
    query['sift:userId'] = userId
    AssService.getGateways(query).then((result)=>{
      setGatewaysList(result);
    })

    let query2 = {}
    query2['sift:userId'] = userId
    query2['sift:ntype'] = "camera,log,gateway,Mobile,Scheduler,associations,Composite,Weather,WeeklyPlanner,Connectors,Zigbee_EZSP"

    AssService.getObjectsWithAllFilter(query2).then(result2 =>{
      setConnectedObject(result2.connectedCount);
    })
    .catch(e => {
      throw new Error(`API error:${e?.message}`)
    });

  },[dummyState])

  useEffect(()=>{
    console.log(idToDelete)
  },[idToDelete])

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: '#'
    },
    {
      accessorKey: 'connected',
      cell: props => (props.getValue() !== false ? <box-icon class='connection' color="#afe37b" type="solid" name="circle" /> : <box-icon class='connection' color="red" type="solid" name="circle" />),
      header: t('objects.table.header.connected')
    },/*
    {
      accessorKey: 'realName',
      header: t('objects.table.header.name')
    },*/
    {
      accessorKey: 'userName',
      header: t('objects.details.user_name')
    },
    {
      accessorKey: 'typeName',
      header: t('objects.table.header.type')
    },
    {
      accessorKey: 'firmware',
      header: t('objects.table.header.firmware')
    },
    {
      accessorKey: 'lastEvent',
      cell: props => (props.getValue() != null ? dates.format(i18n, props.getValue()) : t('objects.table.no_date')),
      header: t('objects.table.header.last_event')
    },
    {
      accessorKey: 'delete',
      cell: (props) => (
        <div
          className='table-icon table-clickable'
          onClick={() => {console.log(props.row.original.id);setIdToDelete(props.row.original.id);setConfirmPopupState(true)}}>
          <box-icon type="solid" name="trash-alt"></box-icon>
          <span class="nav-text">{t('objects.table.delete')}</span>
        </div>
      ),
      header: t('objects.table.header.actions')
    },
    {
      cell: (props) => (
        <div
          className='pointer table-icon'
          onClick={() => {onEdit(props.row.original.id)}}>
          <span class="nav-text">{t('objects.table.details')}</span>
          <box-icon class='icon arrow' name='plus-circle'></box-icon>
        </div>
      ),
      header: t('objects.table.header.actions')
    }
  ], [i18n, t])

  async function fetchObjects (parameters) {
    let query = {}; /* semi-colon is important because of the parenthesis below. */

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['sift:userId'] = userId
    query['sift:ntype'] = "camera,log"

    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    try {
      var result = await AssService.getObjects(query);
      return result//.then((datas) => {console.log("nb equiepements:"+datas.count)})
    } catch (e) {
      throw new Error(`API error:${e?.message}`)
    }
  }

  function onEdit (id) {
    AssService.getObject(id)
                 .then((response) => {
                   console.log(response)
                   setEditPopupContent(response)
                   setEditPopupState(o => !o)
                 })
                 .catch((error) => {
                   console.log(error)
                 })
  }

  function onEditObject (properties) {
    console.log("Edit")
    setEditPopupState(false)
  }

  /*
  function onDelete(id) {
    try {
      id = idToDelete
      console.log(id)
      setIdToDelete(0)
      setConfirmPopupState(false)
      return AssService.deleteObject(id).then(()=>{setDummyState(Date.now());})
    } catch (e) {
      throw new Error(`API error:${e?.message}`)
    }    
  }*/

  function onDelete() {
    setIdToDelete(0);
    setDummyState(Date.now());
  }

  /*
  function onAdd() {
    try {
      if (type.label === "BoardGate")
      {
        type.realName += "swing/"
      }
      var body = {realName: type.realName+id, typeName: type.value}

      console.log(userId)
      console.log(body)
      setAddPopupState(false)
      
      return AssService.createObject(userId,body).then(()=>{setDummyState(Date.now());})
    }
    catch (e) {
      throw new Error(`API error:${e?.message}`)
    }   
  }
  */

  function onPopupExit() {
    setDummyState(Date.now());
    setAddObjectPopupState(false);
    setAddGatewayPopupState(false);
  }

  return (
    <>
      <UserNav title={t('users.objects.title')} />
      <DeletePopUp action={[idToDelete,onDelete]} t={t} />
      <AddGatewayPopUp action={[addGatewayPopupState,userId,onPopupExit]} t={t} />
      <AddObjectPopUp action={[addObjectPopupState,userId,onPopupExit]} t={t} />
      <h5 className='floating-button'>{t('objects.title.gateways')}</h5>
      <button className='floating-button' onClick={()=>{setAddGatewayPopupState(true)}}>
        <AddCircleIcon/>{t('objects.add.button')}
      </button>
      <div className="floating-cancel"></div>
      <div className='tableGatewayProfile'>
        {gatewaysList?.map((gateway,index)=>{
          return(
            <>
              <Gateway key={index} gateway={gateway} t={t} i18n={i18n} action={onPopupExit}></Gateway>
            </>
          )
        })}
      </div>
      <h5 className='floating-button'>{t('objects.title.objects')} - {connectedObject} connectés</h5>
      <button className='floating-button' onClick={()=>{setAddObjectPopupState(true)}}>
        <AddCircleIcon/>{t('objects.add.button')}
      </button>
      <div className='tableObjectProfile'>
        <QueryClientProvider client={queryClient}>
          <Table
            columns={columns}
            feeder={fetchObjects}
            externalTrigger={dummyState} />
        </QueryClientProvider>
      </div>
      <ObjectPopup
        callback={onEditObject}
        control={editPopupState}
        values={editPopupContent}
        t={t}
        otaAction={setOtaStatus}
        setOtaUrl={setOtaUrl}
        otaUrl={otaUrl} />
    </>
  )
}

export default withTranslation('ui')(UserPage)