const config = {
    /*--
    --*/
    'server' : () => {
        var currentServer = window.location.hostname;
        return currentServer;
    },
    'version' : "1.3.1",

    /**
    * List of all supported object by AvisenOne servers,
    * Access by typeName
    * @param {filter} properties : Can appear in filter list
    * @param {add} properties : Can appear in add object list
    * @param {ota} properties : Ota type -> 0 - No ota / 1 - MQTT/Gateway ota / 2 - zigbee ota
    */
    'objects' : {
        "default" : {filter: 0, add: 0, ota: 0, tab_details:[{"name":"userName","editable":1,"pretreatment":"none"},{"name":"realName","editable":0,"pretreatment":"none"}],tab_parameters:[],tab_history:[]},
        "VDoorBell" : { label: "VDoorBell", value: "VDoorBell", realName: 'access/vdp/', filter: 1, add: 1, ota: 0 },
        "BoardGate" : { label: "BoardGate", value: "BoardGate",  realName: 'gate/', parameters : ["mac_address"], filter: 1, add: 0, ota : 0 },
        "AtHomeVDP" : { label: "AtHomeVDP", value: "AtHomeVDP", realName: 'access/vdp/', filter: 1, add: 1, ota : 0 },
    },    
    /**
     * List of supported objects by server
     * Access by server name
    // La modification de cette liste va impacter les menus déroulant:
            - filtre de recherche dans la page objects/userObjects
            - choix d'ajout dans la page user/userObjects
    --*/
    'supported_objects' : [
        { label: "VDoorBell", value: "VDoorBell", realName: 'access/vdp/', filter: 1, add: 1, ota: 0 },
        { label: "BoardGate", value: "BoardGate",  realName: 'gate/', parameters : ["mac_address"], filter: 1, add: 0, ota : 0 },
        { label: "AtHomeVDP", value: "AtHomeVDP", realName: 'access/vdp/', filter: 1, add: 1, ota : 0 },
    ],
    /*--
    // Liste des commandes executables par une gateway
    --*/
    'gateway_commands' : [
        { label: "Command1"},
        { label: "Command2"}
    ]
};

export default config;