import { createContext, useState, useEffect } from 'react'
import AuthenticationService from '../../services/AuthenticationService'
import WSConnector from '../../services/WsConnector'


const AuthContext = createContext({})

function connect () {
    var cx = WSConnector()

    console.info('Opening WS Connection')

    /*
    cx.registerListener(null, 'status', (msg) => {
        console.debug(msg)
    })*/

    cx.open({
        onWsOpen : function (event) {
            console.info('WS Connection established')

            cx.registerListener(null, 'status', (msg) => {
                console.debug(msg)
            })

            cx.send('/', 'login', localStorage.getItem('token')/*'eyJraWQiOiI1MDUxZmIxZi1lMmUyLTRlMWUtYWI1ZS02MmIzN2Y1NDhhNWQiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJzYXVyb25AYXZpZHNlbi5jb20iLCJ1aWQiOjEsIm5iZiI6MTY5NDYxMTYwNSwic2NvcGUiOiJST0xFX1NVUEVSX0FETUlOIiwiaXNzIjoiaHR0cDpcL1wvbG9jYWxob3N0Ojc2ODIiLCJleHAiOjE2OTQ2MzMyMDUsImlhdCI6MTY5NDYxMTYwNX0.jcUCa3ekMt_0BhbUFYT9keITsc_1jHbdYKF31kmwCo503RYbNYXNf6BUXV_-_kN7tLUrVQ6grtn3idB53Ix2Xv42xzWwgHDsmQPF20G317AZVITt9cimAzoBKaLTNctVhQHP_dwwqqxqwUItKAtoVqEaxZseYp9yt-SY1lLBprLYhNyqiVqd6jNm1ardveULXHCSGpb9gBYkRuPVeRgiykG5enFLiydpkdFTah94L6jwCnpR6K5P40uN4SxE8qn9TGZB4iuG1KnieXEgGxdgduZZYqC8q4PlZ98Xa-T5IAvQJqmjpCqnTrHakkVKjQ73BI-0rHzDG0MQ3E591A1uiA'*/)
        },

        onWsClose : function (listener) {
            console.info('WS Connection closed')
        },

        onWsError : function (listener) {
            console.warn('WS Connection error')
        }
    })

    return cx
}

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({})

    /* Effect is only used on initial page display.
     *
     * All the explicit authentication updates are done using the login and
     * logout functions below.
     */
    useEffect(() => {
        const token = localStorage.getItem('token')

        console.info('Checking token ')
        console.info(token)

        if (token != null) {
            AuthenticationService
                .getCurrentUser()
                .then((response) => {
                    login(response?.login,
                        response?.roles,
                        response?.userId,
                        response?.userName)
                })
                .catch((err) => {
                    console.error(err)
                    logout()
                })
        } else {
            logout()
        }
    }, [])

    function login (login, roles, userId, userName) {
        console.info('Authenticated as ' + login)

        setAuth({
            login,
            roles,
            socket: connect(),
            userId,
            userName
        })
    }

    function logout () {
        console.info('Clearing authentication')

        if (auth.socket != null)
            auth.socket.close()

        setAuth({})
        localStorage.removeItem('token')
    }

    const stateValues = {
        auth,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={stateValues}>{children}</AuthContext.Provider>
    )
}

export default AuthContext
