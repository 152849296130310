import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import config from '../../config'
import dates from '../../utils/dates'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import GatewayDeletePopUp from '../popups/GatewayDeletePopUp';
import OtaGatewayPopUp from '../popups/OtaGatewayPopUp.js';
import DetailsGatewayPopUp from '../popups/DetailsGatewayPopUp.js';

const Gateway = ({gateway,t,i18n,action}) => {

    const [deletePopUpState,setDeletePopUpState] = useState(false);
    const [morePopUpState,setMorePopUpState] = useState(false);
    const [otaGatewayPopupState, setOtaGatewayPopupState] = useState(false)
    const navigate = useNavigate()

    var gatewayType = '';

    if (gateway.realName.startsWith("AtHome"))
    {
        gatewayType = 'AtHome';
    }
    else if (gateway.realName.startsWith("VDoorBell"))
    {
        gatewayType = 'VDoorBell';
    }
    else if (gateway.realName.startsWith("WebBrowser"))
    {
        gatewayType = 'WebBrowser';
    }
    else
    {
        gatewayType = 'zigbee'
    }

    function onDelete() {
        setDeletePopUpState(false)
        action()
    }

    function onUpdate() {
        setOtaGatewayPopupState(false)
        action()
    }

    function onClose() {
        setMorePopUpState(false)
    }

    return (
        <div className='gateway'>
                
                <h6 className='floating-button'>{gateway.userName}</h6>
                <button className='floating-button' onClick={()=>{setMorePopUpState(true)}}>
                    {t('users.gateways.button.more')}<AddCircleOutlineIcon/>
                </button>
                {(gatewayType === 'zigbee')&&<>
                <button className='floating-button' onClick={()=>{setDeletePopUpState(true)}}>
                    <DeleteIcon/>{t('users.gateways.button.delete')}
                </button>
                <DetailsGatewayPopUp action={[morePopUpState,gateway,onClose]} t={t}></DetailsGatewayPopUp>
                <GatewayDeletePopUp action={[deletePopUpState,gateway.id,onDelete]} t={t}></GatewayDeletePopUp>
                <OtaGatewayPopUp action={[otaGatewayPopupState,gateway.id,onUpdate]} t={t} />
                </>}
                <table className='gateway'>
                <thead>
                    <tr>
                    <th scope="col" className='table_id'>{t('users.gateways.table.header.id')}</th>
                    <th scope="col" className='table_online'>{t('users.gateways.table.header.connected')}</th>
                    <th scope="col" className='table_name'>{t('users.gateways.table.header.realname')}</th>
                    <th scope="col">{t('users.gateways.table.header.lastevent')}</th>
                    {(gatewayType === 'zigbee')&&<><th scope="col">{t('users.gateways.table.header.firmware')}</th></>}
                    {(gatewayType === 'zigbee')&&<><th scope="col">{t('users.gateways.table.header.ncp')}</th></>}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{gateway.id}</td>
                    <td>{gateway.connected? <box-icon class='connection' color="#afe37b" type="solid" name="circle" /> : <box-icon class='connection' color="red" type="solid" name="circle" />}</td>
                    <td>{gateway.realName}</td>
                    <td>{gateway.lastEvent!= null ? dates.format(i18n, gateway.lastEvent/1000) : t('objects.table.no_date')}</td>
                    {(gatewayType === 'zigbee')&&<><td>{gateway.firmware}</td></>}
                    {(gatewayType === 'zigbee')&&<><td>{gateway.components[0].resource.firmware}</td></>}
                    </tr>
                </tbody>
                </table>
                {(gatewayType === 'zigbee')&&<>
                <button>
                    {t('users.gateways.button.command')}
                </button>
                <button>{t('users.gateways.button.reboot')}</button>
                <button onClick={()=>{setOtaGatewayPopupState(true)}}>{t('users.gateways.button.OTA')}</button>
                <button onClick={navigate('/users/' + gateway.user.resource.id + '/objects/network/' + gateway.id + '/actions')}>{t('users.gateways.button.network')}</button></>}
        </div>
    );
  }
  
  export default Gateway;