import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../globalState/context/AuthProvider';
import AssService from '../../services/AssService';

const Cartography = ({gatewayId}) => {

    const context = useContext(AuthContext)
    const [networkList,setNetworkList] = useState([])
    
    console.log(context)

    //context.auth.socket.registerListener('*',null,onNetworkLIstReceived)

    function onNetworkLIstReceived(event){
      console.log(event)
      
    }

    /*
    AssService.getObject(gatewayId).then((result)=>{
      console.log(result)
      //AssService.sendGatewayCommand()
    })*/

    useEffect(()=>{
      console.log(networkList)
      if(networkList.length < 1)
      {
        AssService.getObject(gatewayId).then((result)=>{
          console.log(result)
          AssService.sendGatewayCommand(result.user.resource.id,gatewayId,"test")
        })
        
      }
      else
      {
        
      }
    },[networkList])


    return(<>
        <div class="row">
         <div class="col">
             <h7>Id noeud</h7>
         </div>
         <div class="col">
           <h7>Adresse IEEE</h7>
         </div>
         <div class="col">
           <h7>Type</h7>
         </div>
         <div class="col">
           <h7>Catégorie</h7>
         </div>
         <div class="col">
           <h7>Fabricant</h7>
         </div>
         <div class="col">
           <h7>Nom</h7>
         </div>
         <div class="col">
           <h7>Protocole</h7>
         </div>
       </div>
       {networkList.map((data,index)=>{
        return(<></>);
       })}
   </>);
}

export default Cartography